<template>
  <v-container>
    <v-row no-gutters>
      <v-col cols="12">
        <router-view />
        <vue-headful :title="pageTitle" />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import vueHeadful from 'vue-headful';

export default {
  name: 'WelcomeLandingPage',
  components: {
    vueHeadful,
  },

  computed: {
    partnerName() {
      return this.$store.state.configs && this.$store.state.configs.title
        ? this.$store.state.configs.title
        : '';
    },

    pageTitle() {
      return this.partnerName ?? 'Guestlogix';
    },
  },
};
</script>
